export default {
  routerList: [
    {
      path: '/liaisonStation',
      redirect: '/liaisonStationHome'
    },
    {
      path: '/liaisonStationHome',
      component: () => import('@/views/liaisonStation/liaisonStation/homePage.vue'),
      name: '家站首页',
      children: []
    },
    {
      path: '/liaisonNewDetails',
      component: () => import('@/views/liaisonStation/liaisonStation/liaisonNewDetails.vue'),
      name: '联络站新闻详情',
      children: []
    },
    {
      path: '/liaisonNewList',
      component: () => import('@/views/liaisonStation/liaisonStation/liaisonNewList.vue'),
      name: '联络站新闻列表',
      children: []
    },
    {
      path: '/liaisonRep',
      component: () => import('@/views/liaisonStation/liaisonStation/liaisonRep.vue'),
      name: '联络站代表列表',
      children: []
    },
    {
      path: '/liaisonList',
      component: () => import('@/views/liaisonStation/liaisonStation/liaisonList.vue'),
      name: '联络站列表',
      children: []
    },
    {
      path: '/liaison',
      component: () => import('@/views/liaisonStation/liaisonStation/liaison.vue'),
      name: '联络站功能页面',
      children: []
    },
    {
      path: '/liaisonRepDetails',
      component: () => import('@/views/liaisonStation/liaisonStation/repDetails.vue'),
      name: '联络站代表详情',
      children: []
    },
    {
      path: '/leaveMessageList',
      component: () => import('@/views/liaisonStation/liaisonStation/leaveMessageList.vue'),
      name: '留言列表',
      children: []
    },
    {
      path: '/leaveMessageDetails',
      component: () => import('@/views/liaisonStation/liaisonStation/leaveMessageDetails.vue'),
      name: '留言详情',
      children: []
    }
  ]
}
