<template>
  <router-view  />
</template>
<script>
// import { createMetaInfo } from 'vue-meta-info'
export default {
  // metaInfo: createMetaInfo(),
  name: 'App-',
  data () {
    return {
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/css/skinPeeler.scss";
@import "@/assets/css/public.scss";
body {
  @include homeBg("bgStartColor", "bgEndColor", "bgImg");
  @include setColor("color");
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
