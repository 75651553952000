
import global from '@/assets/js/global'
const data = {}
if (global.tenantCode === '150521000000000001' || global.title === '测试环境' || global.tenantCode === '610800000000000001') {
  // 公众参与
  data.keepRecordData = {
    // host: '中国人民政治协商会议科尔沁左翼中旗委员会办公室', // 主办单位
    host: '中国人民政治协商会议克拉玛依市委员会',
    // phone: ' 0371-65506163', // 联系电话
    // address: '河南省郑州市花园路82号', // 地址
    // recordNumber: '蒙ICP备2023003090号-1' // 备案号
    recordNumber: '新ICP备2023003090号-1',
    // copyright: '中国人民政治协商会议河南省委员会办公厅', // 版权所有
    // jiguanImg: global.imageUrl + '/jiguan.png'
  }
  // 数字政协
  data.zhrdList = [
    { label: '数字政协综合管理平台', bgColor: '#f56c6c', url: global.serviceUrl, icon: 'icon-tiaochawenjuan' },
    { label: '委员建言（民情在线）', bgColor: '#AC917C', url: '/repAdvice', icon: 'icon-yijianfankui' }
  ]
  // 专题专栏
  data.ztzlList = [
    { label: '党史学习教育', url: 'https://www.baidu.com', imgUrl: global.imageUrl + '/dsxxjy.png' },
    { label: '6+1联动监督', url: 'https://www.baidu.com', imgUrl: global.imageUrl + '/ldjd.png' },
    { label: '全国代表达会', url: 'https://www.baidu.com', imgUrl: global.imageUrl + '/huazhang.png' }
  ]
  // 委员天地
  data.hdjlList = [
    { label: '委员风采', bgColor: '#6CA3F3', url: '/newList?navCode=cppccwz-061&navName=委员风采', icon: 'icon-tiaochawenjuan' },
    { label: '委员履职', bgColor: '#6CA3F3', url: '/newList?navCode=cppccwz-wygzs&navName=委员履职', icon: 'icon-zhengcefagui2' },
    { label: '书画摄影展', bgColor: '#6CA3F3', url: '/newList?navCode=cppccwz-063&navName=书画摄影展', icon: 'icon-zhengcefagui2' },
    { label: '委员讲堂', bgColor: '#f56c6c', url: '/newList?navCode=cppccwz-062&navName=委员讲堂', icon: 'icon-book' }
  ]
  data.picLiList = [
    { label: '农牧业委员会', url: '/newList?navCode=cppccwz-nmywyh&navName=农牧业委员会', imgUrl: global.imageUrl + '/rd2_1.png' },
    { label: '经济科技法制委员会', url: '/newList?navCode=cppccwz-jjkjfzwyh&navName=经济科技法制委员会', imgUrl: global.imageUrl + '/rd2_2.png' },
    { label: '提案文史学习委员会', url: '/newList?navCode=cppccwz-tawsxxwyh&navName=提案文史学习委员会', imgUrl: global.imageUrl + '/rd2_3.png' },
    { label: '文教卫生民族宗教委员会', url: '/newList?navCode=cppccwz-wjwsmzzjwyh&navName=文教卫生民族宗教委员会', imgUrl: global.imageUrl + '/rd2_4.png' }
    // { label: '城乡建设和资<br />源环境委员会', url: '/newList?navCode=cxjshzyhjwyh&navName=城乡建设和资源环境委员会', imgUrl: global.imageUrl + '/rd2_5.png' },
    // { label: '教科卫体文化文<br />史和学习委员会', url: '/newList?navCode=jkwtwhwshxxwyh&navName=教科卫体文化文史和学习委员会', imgUrl: global.imageUrl + '/rd2_6.png' },
    // { label: '社会法制和民<br />族宗教委员会', url: '/newList?navCode=shfzhmzzjwyh&navName=社会法制和民族宗教委员会', imgUrl: global.imageUrl + '/rd2_7.png' },
    // { label: '港澳台侨和<br />外事委员会', url: '/newList?navCode=gatqhwswyh&navName=港澳台侨和外事委员会', imgUrl: global.imageUrl + '/rd2_8.png' }
  ]
  // 公众参与
  data.participationList = [
    { label: '委员会客厅', img: global.imageUrl + '/p-wyhkt.png', url: '/committeelivingroom', imgUrl: 'icon-tiaochawenjuan' },
    { label: '提案线索交互', img: global.imageUrl + '/p-taxs.png', url: '/proposalClue', imgUrl: 'icon-tiaochawenjuan' },
    { label: '文史资料征集', img: global.imageUrl + '/p-wszl.png', url: '/historicalData', imgUrl: 'icon-tiaochawenjuan' },
    { label: '网络议政', img: global.imageUrl + '/p-wlyz.png', url: '/networkDiscuss', imgUrl: 'icon-tiaochawenjuan' },
    { label: '网上咨询', img: global.imageUrl + '/p-wszx.png', url: '/onlineConsultation', imgUrl: 'icon-tiaochawenjuan' },
    { label: '意见征集', img: global.imageUrl + '/p-yjzj.png', url: '/solicitationOpinions', imgUrl: 'icon-tiaochawenjuan' }
  ]
}
export default {
  data
}
