import axios from 'axios'
import { ElMessageBox } from 'element-plus'
// import router from '../router/index'
const systemType = 'cppcc'
// 登录
const loginFn = function name (params) {
  return axios.create({
    baseURL: '/api',
    auth: params,
    headers: { 'X-Requested-With': 'XMLHttpRequest', 'X-Requested-System': systemType, 'X-CAPTCHA-TOKEN': params.captchaCode }
  })
}
// 配置axios实例
const http = axios.create({
  baseURL: '/api'
})
const blobHttp = axios.create({
  baseURL: '/api',
  responseType: 'blob'
})

// request拦截器
http.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : null
    if (token) {
      config.headers['X-Auth-Token'] = token
    }
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    config.headers['X-Requested-System'] = systemType
    return config
  },
  err => {
    return Promise.reject(err)
  })
// 请求结束后
http.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response) {
    switch (error.response.status) {
      case 406:
        ElMessageBox.confirm('页面已超时')
          .then(() => {
            sessionStorage.setItem('token', '')
            history.go(0)
          })
          .catch(() => {
            sessionStorage.setItem('token', '')
            history.go(0)
          })
        break
      case 401:
        ElMessageBox.confirm('页面已超时')
          .then(() => {
            sessionStorage.setItem('token', '')
            history.go(0)
          })
          .catch(() => {
            sessionStorage.setItem('token', '')
            history.go(0)
          })
        break
    }
  }

  return Promise.reject(error)
})
// request拦截器
blobHttp.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : null
    if (token) {
      config.headers['X-Auth-Token'] = token
    }
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    config.headers['X-Requested-System'] = systemType
    return config
  },
  err => {
    return Promise.reject(err)
  })
// 请求结束后
blobHttp.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response) {
    console.log(error.response.status)
    switch (error.response.status) {
      case 406:
        ElMessageBox.confirm('页面已超时')
          .then(() => {
            sessionStorage.setItem('token', '')
            history.go(0)
          })
          .catch(() => {
            sessionStorage.setItem('token', '')
            history.go(0)
          })
        break
      case 401:
        ElMessageBox.confirm('页面已超时')
          .then(() => {
            sessionStorage.setItem('token', '')
            history.go(0)
          })
          .catch(() => {
            sessionStorage.setItem('token', '')
            history.go(0)
          })
        break
    }
  }

  return Promise.reject(error)
})
export { http }

// 登录
export const login = params => {
  return loginFn(params).get('/oauth2/v1/token').then(response => response)
}

// 获取验证码
export const getCode = params => {
  return http.get('/captcha/v1/code', { params: params }).then(response => response)
}
// 新闻查询page
export const articlePage = params => {
  params.systemType = systemType
  return http.get('/sys/article/v1/page', { params: params }).then(response => response)
}
// 新闻查询search
export const articleSearch = params => {
  params.systemType = systemType
  return http.get('/sys/article/v1/search', { params: params }).then(response => response)
}
// 查询代表信息Page
export const representativePage = params => {
  params.systemType = systemType
  return http.get('/platform-member/rep/page', { params: params }).then(response => response)
}
// 查询代表信息所有
export const representativePerson = params => {
  params.systemType = systemType
  return http.get('/platform-member/rep/select/person', { params: params }).then(response => response)
}
// 查询代表信息search
export const representativeSearch = params => {
  params.systemType = systemType
  return http.get('/platform-member/rep/search', { params: params }).then(response => response)
}
// 新闻导航查询page
export const navigationPage = params => {
  params.systemType = systemType
  return http.get('/sys/navigation/v1/page', { params: params }).then(response => response)
}
// 代表之家新闻发布查询
export const findNewsRelease = params => {
  params.tenantUUID = global.tenantCode
  params.systemType = systemType
  return http.get('/platform-member/newsRelease/findNewsRelease', { params: params }).then(response => response.data)
}
// 查询联络站
export const getLiaisonPage = params => {
  return http.get('/platform-member/liaison/stations/v1/page', { params: params }).then(response => response.data)
}

export const getTenantSearch = params => {
  return http.get('/auth/tenants/v1/search', { params: params }).then(response => response)
}
// 获取上传文件认证
export const getUploadAuthentication = params => {
  return http.get('/sys/files/v1/policy', { params: params }).then(response => response)
}
// 上传文件
export const fileUpload = params => {
  return http.post(params.url, params.obj).then(response => response)
}

// 删除文件
export const fileDelete = params => {
  return http.post('/sys/files/v1/remove', params).then(response => response)
}

// 查询角色
export const authGroups = params => {
  return http.get('/auth/groups/v1/search', { params: params }).then(response => response)
}
// 提交信访
export const petitionAdd = params => {
  params.systemType = systemType
  return http.post('/congress-petition/petitions/v1/add', params).then(response => response)
}

// 查询数据字典search
export const dictionarySearch = params => {
  return http.get('/sys/settings/v1/search', { params: params }).then(response => response)
}
// 友情链接查询
export const friendlyLinksSearch = params => {
  return http.get('/sys/settings/links/v1/search', { params: params }).then(response => response)
}

// 我向代表提意见
export const repopinionAdd = params => {
  return http.post('/platform-member/leave/message/v1/addLeavingMessage', params).then(response => response)
}
// 获取二维码
export const getQrCode = params => {
  return blobHttp.post('/wx/wx322105e51034177e/v1/qrcode', params).then(response => response)
}
// 发送短信
export const smsCodeSend = params => {
  return http.get('/sys/sms/v1/code/send', { params: params }).then(response => response)
}
// 验证短信
export const smsCodeCheck = params => {
  return http.post('/sys/sms/v1/code/check', params).then(response => response)
}
// 查询留言
export const getStationPage = params => {
  return http.get('/platform-member/leave/message/v1/page', { params: params }).then(response => response)
}
// 提交建言
export const adviceAdd = params => {
  return http.post('/congress-system/advice/v1', params).then(response => response)
}
// 添加提案线索
export const clueAdd = params => {
  return http.post('/congress-system/proposal/clue/v1', params).then(response => response)
}
// 提案线索page
export const cluePage = params => {
  return http.get('/congress-system/proposal/clue/v1/page', { params }).then(response => response)
}

// 史料征集公告page
export const getAnnouncePage = params => {
  return http.get('/congress-politics/collect/notice/v1/page', { params }).then(response => response)
}

// 史料征集添加
export const addHistorical = params => {
  return http.post('/congress-politics/collect/historical/v1', params).then(response => response)
}

// 史料征集page
export const getHistoricalPage = params => {
  return http.get('/congress-politics/collect/historical/v1/page', { params }).then(response => response)
}
