// import global from '@/assets/js/global'
// import axios from 'axios'
import resources from '@/assets/js/resources'
import store from '@/store/index'
import { ElLoading, ElMessage } from 'element-plus'
let loadingInstance = null

export default {
  statusFn (s, m, d) {
    switch (s) {
      case 401:
        if (m === 'login') {
          ElMessage.error(d)
        } else {
          ElMessage.error('登录超时请重新登录')
        }

        break
      case 403:
        ElMessage.error('暂无' + m + '权限')
        break
      case 502:
        ElMessage.error('服务器正在维护中，请稍后再试')
        break
      case 503:
        ElMessage.error('服务器正在维护中，请稍后再试')
        break
      default:
        ElMessage.error(m + '失败')
    }
  },
  buttonShowFn (code) {
    const users = JSON.parse(sessionStorage.getItem('users'))
    if (users) {
      if (String(users.roles).indexOf(code) !== -1) {
        return true
      } else {
        return false
      }
    }
  },
  cloneDeep (data) {
    // string,number,bool,null,undefined,symbol
    // object,array,date
    if (data && typeof data === 'object') {
      // 针对函数的拷贝
      if (typeof data === 'function') {
        const tempFunc = data.bind(null)
        tempFunc.prototype = this.cloneDeep(data.prototype)
        return tempFunc
      }
      // 根据不同的数据类型的深拷贝
      switch (Object.prototype.toString.call(data)) {
        case '[object String]':
          return data.toString()
        case '[object Number]':
          return Number(data.toString())
        case '[object Boolean]':
          // eslint-disable-next-line no-new-wrappers
          return new Boolean(data.toString())
        case '[object Date]':
          return new Date(data.getTime())
        case '[object Array]':
          // eslint-disable-next-line no-case-declarations
          const arr = []
          for (let i = 0; i < data.length; i++) {
            arr[i] = this.cloneDeep(data[i])
          }
          return arr

        // js自带对象或用户自定义类实例
        case '[object Object]':
          // eslint-disable-next-line no-case-declarations
          const obj = {}
          for (const key in data) {
            // 会遍历原型链上的属性方法，可以用hasOwnProperty来控制 （obj.hasOwnProperty(prop)
            obj[key] = this.cloneDeep(data[key])
          }
          return obj
      }
    } else {
      // string,number,bool,null,undefined,symbol
      return data
    }
  },
  // 生成随机数
  generateRandom () {
    return Math.random().toString(16).slice(2)
  },

  getDictionarys (e, dictionarys) {
    let arr = []
    if (dictionarys && dictionarys.length) {
      const list = dictionarys.filter((item) => item.type === e)
      arr = list.length ? list[0].content : []
    }
    return arr
  },
  // 数据字典转换
  transformationFn (arr, e) {
    if (!arr) {
      return ''
    }
    if (e === '' || e === null || e === undefined) {
      return ''
    } else {
      if (String(e).indexOf(',') !== -1) {
        const objArr = e.split(',')
        const objArrret = []
        for (let i = 0; i < arr.length; i++) {
          for (let j = 0; j < objArr.length; j++) {
            if (objArr[j] === arr[i].value) {
              objArrret.push(arr[i].label)
            }
          }
        }
        return objArrret.join(',')
      } else {
        for (let i = 0; i < arr.length; i++) {
          if (String(arr[i].value) === String(e)) {
            return arr[i].label
          }
        }
      }
    }
  },
  getSysViewPage (key) {
    const data = sessionStorage.getItem('sysViewPage')
    if (data) {
      const obj = JSON.parse(data)
      if (key && obj[key]) {
        return obj[key]
      } else {
        return false
      }
    } else {
      return false
    }
  },
  // 列表排序
  listSort (list, sortkey, type, searchKey, searchvalue) {
    // list 需要排序数据，sortkey排序字段名，type 1倒序2正序，searchKey搜索字段名，searchvalue搜索内容
    if (!list) {
      return []
    }
    const arr = list.filter((item) => {
      return String(item[searchKey]).indexOf(searchvalue) !== -1
    })
    if (type) {
      arr.sort((a, b) => {
        return type === 1 ? b[sortkey] - a[sortkey] : a[sortkey] - b[sortkey]
      })
    }
    return arr
  },
  getTableStyleObj () {
    const result = {
      rowStyle: { height: '10px' },
      headerRowStyle: { height: '10px' },
      cellStyle: { padding: '7px 0 5px 0' },
      headerCellStyle: { padding: '5px 0' }
    }
    return result
  },
  tenantCodeSplit (e) {
    if (e.length > 6) {
      return e.slice(0, 6)
    } else {
      return e
    }
  },
  // 全局loading事件
  openFullScreen (options) {
    let obj = {
      fullscreen: true,
      text: 'loading',
      background: 'rgba(0, 0, 0, 0.7)'
    }
    if (options) {
      obj = Object.assign(obj, {}, options)
    }
    // eslint-disable-next-line no-const-assign
    loadingInstance = ElLoading.service(obj)
  },
  // 关闭全局loading事件
  closeFullScreen () {
    // Loading should be closed asynchronously
    loadingInstance.close()
  },

  downloadFn (res) {
    // 答复附件下载
    window.location = res.url
    // const link = document.createElement('a')
    // link.download = res.name
    // link.href = res.url
    // link.click()
  },
  // 下载二维码
  downImg (name, type, imgUrl) {
    // 如果浏览器支持msSaveOrOpenBlob方法（也就是使用IE浏览器的时候），那么调用该方法去下载图片
    if (window.navigator.msSaveOrOpenBlob) {
      const bstr = atob(imgUrl.split(',')[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      const blob = new Blob([u8arr])
      window.navigator.msSaveOrOpenBlob(blob, name + '.' + type)
    } else {
      // 这里就按照chrome等新版浏览器来处理
      const a = document.createElement('a')
      a.href = imgUrl
      a.setAttribute('download', name)
      a.click()
    }
  },
  // 获取所有省
  getprovinces (code) {
    const arr = resources.chinaList.provinces
    const areaArr = arr.filter((res) => res.code === code)
    return code ? areaArr : arr
  },
  // 获取市
  getcities (v) {
    const arr = resources.chinaList.cities
    const arr2 = []
    arr.forEach((item, index) => {
      if (item.provinceCode === v) {
        arr2.push(item)
      }
    })
    return arr2
  },
  // 获取县（区）
  getareas (v) {
    const arr = resources.chinaList.areas
    const arr2 = []
    arr.forEach((item, index) => {
      if (item.cityCode === v) {
        arr2.push(item)
      }
    })
    return arr2
  },
  // 获取镇
  getstreets (v) {
    const arr = resources.chinaList.streets
    const arr2 = []
    arr.forEach((item, index) => {
      if (item.areaCode === v) {
        arr2.push(item)
      }
    })
    return arr2
  },
  getvillage (v) {
    const arr = resources.chinaList.streets
    const obj = {
      provinceCode: null,
      cityCode: null,
      areaCode: null,
      streetCode: v
    }
    arr.forEach((item, index) => {
      if (item.code === v) {
        obj.provinceCode = item.provinceCode
        obj.cityCode = item.cityCode
        obj.areaCode = item.areaCode
      }
    })
    return obj
  },
  // 内容中图片视频设置
  htmlFn (e, imgStyle, videoStyle) {
    if (!e) {
      return ''
    }
    // eslint-disable-next-line no-unneeded-ternary
    const iStyle = imgStyle ? 'width:' + imgStyle + ';' : 'width:650px;'
    // eslint-disable-next-line no-unneeded-ternary
    const vStyle = videoStyle ? 'width:' + videoStyle + ';' : 'width:650px;'
    const pStyle = 'margin: 15px auto;display: block;min-height:400px'
    let content = e.replace(
      // eslint-disable-next-line no-useless-escape
      /<img[\s\S]*?src\s*=\s*[\"|\'](.*?)[\"|\'][\s\S]*?>/g,
      function (match, capture) {
        return "<img style='" + iStyle + pStyle + "' src='" + capture + "'/>"
      }
    )
    content = content.replace(
      // eslint-disable-next-line no-useless-escape
      /<video[\s\S]*?src\s*=\s*[\"|\'](.*?)[\"|\'][\s\S]*?>/g,
      function (match, capture) {
        return (
          '<video controls="controls" type="video/mp4" style=\'' +
          vStyle + pStyle +
          "' src='" +
          capture +
          "'/>"
        )
      }
    )
    content = content.replace(
      // eslint-disable-next-line no-useless-escape
      /<iframe[\s\S]*?src\s*=\s*[\"|\'](.*?)[\"|\'][\s\S]*?>/g,
      function (match, capture) {
        return "<iframe style='" + iStyle + pStyle + "' src='" + capture + "'/>"
      }
    )
    return content
  },
  // 递归数据
  createTreeData (list, parentId, pcodeString, codeString) {
    const arr = []
    let tempObj
    if (list.length > 0) {
      if (pcodeString && codeString) {
        list.forEach((item, index) => {
          if (item[pcodeString] === parentId) {
            const treeItem = item
            tempObj = this.createTreeData(
              list,
              item[codeString],
              pcodeString,
              codeString
            )
            if (tempObj.length > 0) {
              treeItem.children = tempObj
            }
            arr.push(treeItem)
          }
        })
      } else {
        list.forEach((item, index) => {
          if (item.pcode === parentId) {
            const treeItem = item
            tempObj = this.createTreeData(list, item.code)
            if (tempObj.length > 0) {
              treeItem.children = tempObj
            }
            arr.push(treeItem)
          }
        })
      }
    }
    return arr
  },
  // 打印相关
  doPrint (obj) {
    this.remove_ie_header_and_footer()
    const subOutputRankPrint = obj
    const newContent = subOutputRankPrint.innerHTML
    const oldContent = document.body.innerHTML
    document.body.innerHTML = newContent
    window.print()
    window.location.reload()
    document.body.innerHTML = oldContent
    return false
  },
  // 导出loading事件
  exportLoadingFn (e) {
    store.state.exportLoading = store.state.exportLoading + 1
    if (e) {
      store.state.exportLoadingText = e
    } else {
      store.state.exportLoadingText = '正在导出'
    }
  },
  // 导出完成关闭loading事件
  exportLoadingCloseFn () {
    if (store.state.exportLoading > 0) {
      store.state.exportLoading = store.state.exportLoading - 1
    }
  },
  repDetails (row) {
    store.state.publicObj = row
    store.state.repDetailsShow = true
  },
  // 导出
  exportsFn (name, data) {
    const blob = new Blob([data])
    const link = document.createElement('a')
    link.download = name
    link.href = URL.createObjectURL(blob)
    link.click()
  },

  // 请求头  content-disposition 提取文件名
  getContentdisposition (e) {
    const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
      e['content-disposition']
    )
    return decodeURI(matches[1].replace(/['"]/g, ''))
  },
  quillContentFileter (e) {
    return e === '<p><br></p>' ? '' : e
  },
  // 秒转时分秒格式
  secondToDate (result) {
    const h =
      Math.floor(result / 3600) < 10
        ? '0' + Math.floor(result / 3600)
        : Math.floor(result / 3600)
    const m =
      Math.floor((result / 60) % 60) < 10
        ? '0' + Math.floor((result / 60) % 60)
        : Math.floor((result / 60) % 60)
    const s =
      Math.floor(result % 60) < 10
        ? '0' + Math.floor(result % 60)
        : Math.floor(result % 60)
    return (result = h + ':' + m + ':' + s)
  },
  // 获取当前时间yyyy-MM-dd hh:mm:ss
  getTimeFormat () {
    const dataString = new Date().getTime()
    const time = new Date(dataString)
    const year = time.getFullYear()
    const month = time.getMonth() + 1
    const day = time.getDate()
    const hour = time.getHours()
    const minute = time.getMinutes()
    const second = time.getSeconds()
    return (
      year +
      '-' +
      (month < 10 ? '0' + month : month) +
      '-' +
      (day < 10 ? '0' + day : day) +
      ' ' +
      (hour < 10 ? '0' + hour : hour) +
      ':' +
      (minute < 10 ? '0' + minute : minute) +
      ':' +
      (second < 10 ? '0' + second : second)
    )
  },
  getTenantValue (e) {
    if (!JSON.parse(sessionStorage.getItem('tenants'))) {
      return ''
    }
    if (!JSON.parse(sessionStorage.getItem('tenants')).extend) {
      return ''
    }
    const tenantObj = JSON.parse(sessionStorage.getItem('tenants')).extend
    if (tenantObj.other) {
      if (tenantObj.other[e]) {
        return tenantObj.other[e]
      } else {
        return ''
      }
    } else {
      return ''
    }
  },
  // 根据身份证获取出生日期
  getBirth (idCard) {
    let birthday = ''
    if (idCard != null && idCard !== '') {
      if (idCard.length === 15) {
        birthday = '19' + idCard.slice(6, 12)
      } else if (idCard.length === 18) {
        birthday = idCard.slice(6, 14)
      }
      birthday = birthday.replace(/(.{4})(.{2})/, '$1-$2-')
      // 通过正则表达式来指定输出格式为:1990-01-01
    }
    return birthday
  },
  // 根据身份证获取性别
  getSex (idCard) {
    let sexStr = ''
    if (idCard.length === 18) {
      if (parseInt(idCard.slice(-2, -1)) % 2 === 1) {
        sexStr = '02'
      } else {
        sexStr = '01'
      }
      return sexStr
    } else if (idCard.length === 15) {
      if (parseInt(idCard.slice(-1)) % 2 === 1) {
        sexStr = '02'
      } else {
        sexStr = '01'
      }
      return sexStr
    }
  },
  // 通过日期计算总共天数
  dateDiff (d1) {
    d1 = new Date(d1)
    const d2 = new Date()
    const obj = {}
    const M1 = d1.getMonth()
    const D1 = d1.getDate()
    const M2 = d2.getMonth()
    const D2 = d2.getDate()
    obj.Y =
      d2.getFullYear() -
      d1.getFullYear() +
      (M1 * 100 + D1 > M2 * 100 + D2 ? -1 : 0)
    obj.M = obj.Y * 12 + M2 - M1 + (D1 > D2 ? -1 : 0)
    obj.s = Math.floor((d2 - d1) / 1000) // 差几秒
    obj.m = Math.floor(obj.s / 60) // 差几分钟
    obj.h = Math.floor(obj.m / 60) // 差几小时
    obj.D = Math.floor(obj.h / 24) // 差几天
    return obj.D
    // return str.replace(/\w/g, function (a) {
    // });
  },
  // 判断token是否过期
  isToken () {
    if (!sessionStorage.getItem('token')) {
      return false
    } else {
      const lastAccessTime = Number(sessionStorage.getItem('lastAccessTime'))
      const expires = Number(sessionStorage.getItem('expires'))
      const time = Math.floor(new Date().getTime() / 1000)
      if (time - lastAccessTime >= expires) {
        return false
      } else {
        return true
      }
    }
  },
    // 在线预览
    previewFileEvent (e) {
      console.log(e)
      const fileUrl = e.url
      const ext = fileUrl.substr(fileUrl.lastIndexOf('.') + 1)
      store.state.previewImageShow = true
      store.state.previewImageUrl = fileUrl
      store.state.previewImageType = ext
    },
  // 根据日期获取年月
  getDateStr (e, type) {
    if (!e) {
      return ''
    }
    if (!type) {
      return e.slice(5, 10)
    }
    if (type === 'month') {
      return e.slice(5, 10)
    }
    if (type === 'year') {
      return e.slice(0, 10)
    }
  },
  wzaShowFn () {
    if (window.top.document.getElementById('rrbay_wzatool')) {
      return false
    }
    const url = '?url=' + document.location.href
    window.scrollTo(0, 0)
    window.location.href = '/canyou/index.html' + url
  },
  // 生成验证码（length验证码长度，width验证码宽度，默认4,60）
  getVcode (length, width) {
    const obj = {
      code: '',
      url: ''
    }
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    length = length || 4
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length)
      obj.code += characters.charAt(randomIndex)
    }
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    canvas.width = width || 60
    canvas.height = 30
    context.font = '20px Arial'
    context.textAlign = 'center'
    context.textBaseline = 'middle'
    context.fillStyle = '#555'
    context.clearRect(0, 0, canvas.width, canvas.height)

    const gradient = context.createLinearGradient(0, 0, canvas.width, 0)
    gradient.addColorStop('0', 'magenta')
    gradient.addColorStop('0.5', 'blue')
    gradient.addColorStop('1.0', 'red')
    context.fillStyle = gradient

    context.fillText(obj.code, canvas.width / 2, canvas.height / 2)

    obj.url = canvas.toDataURL('image/png')
    return obj
  }

}
