import axios from 'axios'
import global from '@/assets/js/global'
// 中国省级（省份、直辖市、自治区），地级（城市），县级（区县），乡级（乡镇、街道），村级（村委会、居委会）
const chinaList = {
  provinces: [],
  cities: [],
  areas: [],
  streets: []
}
axios.get(global.ossApi + '/china/provinces.json').then(res => {
  chinaList.provinces = res.data
})
axios.get(global.ossApi + '/china/cities.json').then(res => {
  chinaList.cities = res.data
})
axios.get(global.ossApi + '/china/areas.json').then(res => {
  chinaList.areas = res.data
})
axios.get(global.ossApi + '/china/streets.json').then(res => {
  chinaList.streets = res.data
})

// 更换皮肤集合
const predefineColors = ['#FA4138', '#3388F8', '#01C789', '#9958DC', '#F7889C', '#344A7B']

export default {
  predefineColors,
  chinaList
}
