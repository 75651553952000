
// 科左中旗
// let tenantCode = '150521000000000001'
// let title = '科左中旗'
// let ossApi = '/ossApi'
// let imageUrl = '/ossApi/150521'
// let username = 'kzzqzxwzgly'
// let password = 'Aa123456'
// let serviceUrl = 'http://36.138.52.137:9000/kzzqllb' // 履职服务平台

// 克拉玛依市
let tenantCode = '610800000000000001'
let title = '克拉玛依市'
let ossApi = '/ossApi'
let imageUrl = '/ossApi/610800'
let username = '18729206679'
let password = 'Aa123456'
let serviceUrl = 'http://36.138.183.4:9000/ylszx' // 履职服务平台

switch (process.env.NODE_ENV) {
  case 'development': // 开发环境

  // 克拉玛依市
  tenantCode = '610800000000000001'
  title = '克拉玛依市'
  ossApi = '/ossApi'
  imageUrl = '/ossApi/610800'
  username = '18729206679'
  password = 'Aa123456'
  serviceUrl = 'http://36.138.183.4:9000/' // 履职服务平台

    // 测试版
    // tenantCode = '150924000000000001'
    // title = '测试环境'
    // ossApi = '/ossApi'
    // imageUrl = '/ossApi/150521'
    // username = '17603590664'
    // password = 'Aa123456'
    // serviceUrl = 'http://36.138.183.4:9000/'

    // tenantCode = '150521000000000001'
    // title = '科左中旗'
    // ossApi = '/ossApi'
    // imageUrl = '/ossApi/150521'
    // username = 'kzzqzxwzgly'
    // password = 'Aa123456'
    // serviceUrl = 'http://36.138.52.137:9000/kzzqllb' // 履职服务平台
    break
  default:
    break
}
export default {
  ossApi,
  tenantCode,
  title,
  imageUrl,
  username,
  password,
  serviceUrl
}
