import { createRouter, createWebHistory } from 'vue-router'
import home from '@/router/router-item/home.js'
import liaisonStationRouter from '@/router/router-item/liaison.js' // 联络站
const routes = [
  {
    path: '/',
    redirect: '/homePage'
  },
  // 首页
  {
    path: '/index',
    name: '首页',
    component: () => import('@/views/index/index.vue'),
    children: home.routerList
  },
  // 联络站
  {
    path: '/liaisonStation',
    name: 'liaisonStation',
    component: () => import('@/views/liaisonStation/index.vue'),
    children: liaisonStationRouter.routerList
  },
  // 页面不存在
  {
    path: '/:catchAll(.*)',
    name: 'notFound',
    component: () => import('@/views/notFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
