export default {
  routerList: [

    {
      path: '/index',
      redirect: '/homePage'
    },
    {
      path: '/homePage',
      component: () => import('@/views/index/home/homePage.vue'),
      name: '主页',
      children: []
    },
    {
      path: '/newList',
      component: () => import('@/views/index/home/newList.vue'),
      name: '新闻列表',
      children: []
    },
    {
      path: '/newDetails',
      component: () => import('@/views/index/home/newDetails.vue'),
      name: '新闻详情',
      children: []
    },
    {
      path: '/overview',
      component: () => import('@/views/index/home/overview.vue'),
      name: '政协概览',
      children: []
    },
    {
      path: '/information',
      component: () => import('@/views/index/home/information.vue'),
      name: '政协资料',
      children: []
    },
    {
      path: '/specialSubject',
      component: () => import('@/views/index/home/specialSubject.vue'),
      name: '专题集锦',
      children: []
    },
    {
      path: '/magazine',
      component: () => import('@/views/index/home/magazine.vue'),
      name: '政协杂志',
      children: []
    },
    {
      path: '/delegation',
      component: () => import('@/views/index/home/delegation.vue'),
      name: '代表团',
      children: []
    },
    {
      path: '/representative',
      component: () => import('@/views/index/home/representative.vue'),
      name: '代表',
      children: []
    },
    {
      path: '/search',
      component: () => import('@/views/index/home/search.vue'),
      name: '搜索',
      children: []
    },
    {
      path: '/jgsz',
      component: () => import('@/views/index/home/jgsz.vue'),
      name: '机构设置',
      children: []
    },
    {
      path: '/jgszItem',
      component: () => import('@/views/index/home/jgszItem.vue'),
      name: '机构详情',
      children: []
    },
    {
      path: '/lettersVisits',
      component: () => import('@/views/index/home/lettersVisits.vue'),
      name: '信访',
      children: []
    },
    {
      path: '/repAdvice',
      component: () => import('@/views/index/home/repAdvice.vue'),
      name: '委员建言（民情在线）',
      children: []
    },
    {
      path: '/participation',
      component: () => import('@/views/index/participation/index.vue'),
      name: '公众参与',
      children: []
    },
    {
      path: '/committeelivingroom',
      component: () => import('@/views/index/participation/committeelivingroom/index.vue'),
      name: '委员会客厅',
      children: []
    },
    {
      path: '/proposalClue',
      component: () => import('@/views/index/participation/proposalClue/index.vue'),
      name: '提案线索交互',
      children: []
    },
    {
      path: '/proposalClue/clueList',
      component: () => import('@/views/index/participation/proposalClue/clueList.vue'),
      name: '提案线索列表',
      children: []
    },
    {
      path: '/proposalClue/clueDetial',
      component: () => import('@/views/index/participation/proposalClue/clueDetial.vue'),
      name: '提案线索详情',
      children: []
    },
    {
      path: '/historicalData',
      component: () => import('@/views/index/participation/historicalData/index.vue'),
      name: '文史资料征集',
      children: []
    },
    {
      path: '/historicalData/addHistorical',
      component: () => import('@/views/index/participation/historicalData/addHistorical.vue'),
      name: '文史资料添加',
      children: []
    },
    {
      path: '/historicalData/historicalList',
      component: () => import('@/views/index/participation/historicalData/historicalList.vue'),
      name: '文史资料查询',
      children: []
    },
    {
      path: '/historicalData/historicalDetial',
      component: () => import('@/views/index/participation/historicalData/historicalDetial.vue'),
      name: '文史资料详情',
      children: []
    },
    {
      path: '/networkDiscuss',
      component: () => import('@/views/index/participation/networkDiscuss/index.vue'),
      name: '网络议政',
      children: []
    },
    {
      path: '/onlineConsultation',
      component: () => import('@/views/index/participation/onlineConsultation/index.vue'),
      name: '网上咨询',
      children: []
    },
    {
      path: '/solicitationOpinions',
      component: () => import('@/views/index/participation/solicitationOpinions/index.vue'),
      name: '意见征集',
      children: []
    }
  ]
}
