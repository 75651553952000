import { createStore } from 'vuex'

export default createStore({
  state: {
    tableConfig: {
      loading: false,
      tableData: []
    },
    exportLoading: 0,
    exportLoadingText: '',
    repDetailsShow: false,
    previewImageShow: false, // 附件预览弹窗显示隐藏控制
    previewImageUrl: '', // 附件预览图片路径
    previewImageType: '', // 附件预览文件类型
    publicObj: {},
    questionObj: {}
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
