<template>
  <div class="breadcrumbBox">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item
        v-for="(i, x) in routeName"
        :key="x"
        :to="{ path: i.path }"
        >{{ i.name }}</el-breadcrumb-item
      >
    </el-breadcrumb>
  </div>
</template>
<script>
import { } from '@/api/api'
import { useRouter } from 'vue-router'
export default {
  name: 'breadcrumbBox',
  components: {
  },
  data () {
    return {
      routeList: []
    }
  },
  setup () {
    const router = useRouter()
    function linkToAbout (e) {
      router.push({
        path: e.path,
        query: { code: e.code }
      })
    }
    return {
      linkToAbout
    }
  },
  computed: {
    routeName () {
      // const path = (this.$route.matched[this.$route.matched.length - 1]).path
      console.log(this.$route.matched)
      return this.$route.matched
    }
  },
  mounted () {
  },
  created () {
  },
  methods: {
    createTreeData (list, pcode) {
      list.forEach((item, index) => {
        if (item.code === pcode) {
          this.routeList.unshift(item)
          this.createTreeData(list, item.pcode)
        }
      })
      return this.routeList
    },
    goRouter (e) {
      this.linkToAbout(e)
    },
    goHome () {
      this.$router.push('/terrace')
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss">
.breadcrumbBox {
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 15px 0;
  .homeIcon {
    cursor: pointer;
  }
  .homeIcon:hover {
    opacity: 0.5;
  }
  .el-breadcrumb__item {
    display: flex;
    align-items: center;
  }
  .el-breadcrumb {
    display: flex;
  }
}
</style>
