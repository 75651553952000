<template>
  <el-dialog
    :lock-scroll="false"
    append-to-body
    v-model="$store.state.repDetailsShow"
    title="详情"
    width="50%"
    :before-close="handleClose"
    align-center
    destroy-on-close
    center
    @open="open"
  >
    <div class="repBox">
      <div class="photoBox">
        <img v-if="dataObj.photo" :src="dataObj.photo" alt="" />
        <img v-else :src="global.imageUrl + '/nophoto.png'" alt="" />
      </div>
      <el-descriptions column="2" border>
        <el-descriptions-item label="姓名">{{
          dataObj.name
        }}</el-descriptions-item>
        <el-descriptions-item label="性别">{{
          $common.transformationFn(
            $common.getDictionarys("sex", dictionarys),
            dataObj.sex
          )
        }}</el-descriptions-item>
        <el-descriptions-item label="民族">{{
          $common.transformationFn(
            $common.getDictionarys("nation", dictionarys),
            dataObj.nation
          )
        }}</el-descriptions-item>
        <el-descriptions-item label="政治面貌">{{
          $common.transformationFn(
            $common.getDictionarys("politicsStatus", dictionarys),
            dataObj.politicalStatus
          )
        }}</el-descriptions-item>
        <el-descriptions-item label="委员证号">{{
          dataObj.representative
        }}</el-descriptions-item>
        <el-descriptions-item label="界别">{{
          dataObj.circles
        }}</el-descriptions-item>
        <el-descriptions-item label="专委会（室）">{{
          dataObj.delegation
        }}</el-descriptions-item>
        <el-descriptions-item label="委员类别">{{
          $common.transformationFn(
            $common.getDictionarys("rep_category_cppcc", dictionarys),
            dataObj.representType
          )
        }}</el-descriptions-item>
      </el-descriptions>
    </div>
  </el-dialog>
</template>
<script>
import { dictionarySearch } from '@/api/api.js'
import global from '@/assets/js/global'
export default {
  name: 'home-representative',
  data () {
    return {
      global: global,
      dataObj: {},
      dictionarys: []
    }
  },
  components: {},
  created () {

  },
  methods: {

    open () {
      this.getDictionary()
      this.dataObj = this.$store.state.publicObj
      console.log(this.dataObj)
    },
    // 查询字典数据
    getDictionary () {
      const type = 'nation,rep_category_cppcc,educationBackground,politicsStatus,dbzzyy,publicStatus,degree,representType,sex,trueOrFalse,electionMethods,major,institutionType'
      dictionarySearch({ type: type, tenantCode: global.tenantCode }).then(res => {
        if (res.status === 200) {
          this.dictionarys = res.data
        }
      })
    },
    handleClose () {
      this.$store.state.repDetailsShow = false
    }
  }
}
</script>
<style lang="scss">
.repBox {
  max-height: 60vh;
  padding: 0 0 20px 0;
  box-sizing: border-box;
  overflow: auto;
}
.photoBox {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  img {
    width: 100px;
    border: 1px solid #f9f9f9;
  }
}
</style>
