import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import selfDirectives from '@/assets/js/directives.js'

import ElementPlus from 'element-plus'// element
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/dist/index.css'
import common from '@/assets/js/common.js'// 公用方法
import commonData from '@/assets/js/commonData.js'// 公共数据
import moment from 'moment'// 时间插件
import '@/assets/icon/iconfont.css' // 引入阿里图标 CSS
import breadcrumb from './components/breadcrumb'// 面包屑组件
import addressSelection from './components/common/addressSelection'// 地址选择组件
import repDetails from './components/common/repDetails'// 委员详情
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import '@/assets/css/public.scss'
import * as echarts from 'echarts' // echarts
import vueSeamless from 'vue-seamless-scroll/src'
import MetaInfo from 'vue-meta-info'
import BaiduMap from 'vue-baidu-map-3x'

const app = createApp(App)
app.config.globalProperties.$common = common
app.config.globalProperties.$commonData = commonData
app.config.globalProperties.$moment = moment
app.config.globalProperties.$echarts = echarts
app.use(selfDirectives) // 自定义指令
app.use(MetaInfo)
app.use(BaiduMap, {
  ak: 'YPoexHaIEUOBjqyL4CoEOYnnly8sa6ST'
})
app.component('breadcrumb-box', breadcrumb)
app.component('address-selection', addressSelection)
app.component('rep-details', repDetails)
// 防止修改sessionStorage
window.addEventListener('storage', (e) => {
  sessionStorage.setItem(e.key, e.oldValue)
})

// 全局路由守卫
// router.beforeEach((to, from, next) => {
//   document.title = to.name ? to.name : ''
//   next()
// })
app.use(store).use(router).use(ElementPlus, { locale: zhCn }).use(vueSeamless).mount('#app')
